import { useEffect, useState, useCallback, useRef } from 'react';
import { ucFirst } from '../../utils/caseconverters';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';

import Banner from '../Banner/Banner';
import Logo from '../Logo/Logo';

import styles from './Header.module.scss';

const Header = ({
    items = [],
    button = {},
    modifiers = [],
    navActivePages = [],
    banner = {},
    isErrorPage = false,
}) => {
    const { t } = useTranslation();

    const [mounted, setMounted] = useState(false);
    const [isExpanded, setExpanded] = useState(false);
    const [hideHeader, setHideHeader] = useState(false);
    const [hasBackgroundColor, setHasBackgroundColor] = useState(false);

    const lastScrollTop = useRef(0);

    useEffect(() => {
        setMounted(true);
    }, []);

    const handleScroll = useCallback(() => {
        const scrollY = window.scrollY || document.documentElement.scrollTop;
        const scrollSpeed = scrollY - lastScrollTop.current;
        const isScrollingDown = scrollSpeed > 0;

        if (scrollY < 5) {
            setHideHeader(false);
        } else if (scrollSpeed >= 6 && isScrollingDown) {
            setHideHeader(true);
        } else if (scrollSpeed <= -6 && !isScrollingDown) {
            setHideHeader(false);
        }

        if (scrollY >= 250 && !hasBackgroundColor) {
            setHasBackgroundColor(true);
        } else if (scrollY < 250 && hasBackgroundColor) {
            setHasBackgroundColor(false);
        }

        lastScrollTop.current = scrollY;
    }, [hideHeader, hasBackgroundColor]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    const handleClick = () => {
        const isMobile = window.innerWidth < 992;
        setExpanded(!isExpanded);

        if (!isExpanded && isMobile) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.removeAttribute('class');
        }
    };

    const classes = classNames(
        styles['Header'],
        modifiers.map((modifier) => styles['Header--' + ucFirst(modifier)]),
        {
            [styles['Header--IsExpanded']]: isExpanded,
            [styles['Header--Hide']]: hideHeader,
            [styles['Header--BackgroundColor']]: hasBackgroundColor,
            [styles['Header--Visible']]: mounted,
        }
    );

    const toggleText = isExpanded
        ? t('Header.closeMenu')
        : t('Header.openMenu');

    const hasButton = !!button.href && !!button.title;

    return (
        <header className={classes}>
            {!banner && <Banner {...banner} />}
            <div className={styles['Header__Container']}>
                {isErrorPage ? (
                    <div className={styles['Header__Logo']}>
                        <Logo dimensions={{ width: '100px' }} />
                        <span className={'sr-only'}>{t('Header.logo')}</span>
                    </div>
                ) : (
                    <a className={styles['Header__Logo']} href={'/'}>
                        <Logo dimensions={{ width: '100px' }} />
                        <span className={'sr-only'}>
                            {t('Header.toStartPagee')}
                        </span>
                    </a>
                )}
                <FocusTrap active={isExpanded}>
                    <div>
                        <button
                            className={styles['Header__Toggle']}
                            onClick={() => {
                                handleClick();
                            }}
                            aria-controls={'navigation'}
                            aria-expanded={isExpanded}>
                            <span className={styles['Header__Line']} />
                            <span className={'sr-only'}>{toggleText}</span>
                        </button>
                        <nav
                            id={'navigation'}
                            className={styles['Header__Navigation']}>
                            <ul className={styles['Header__List']}>
                                {items.map((item, index) => (
                                    <ListItem
                                        key={item.link.title}
                                        navActivePages={navActivePages}
                                        {...item}
                                    />
                                ))}
                                {hasButton && (
                                    <li className={styles['Header__Item']}>
                                        <a
                                            className={styles['Header__Button']}
                                            href={button.href}
                                            target={button.target}>
                                            {button.title}
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </nav>
                    </div>
                </FocusTrap>
            </div>
        </header>
    );
};

const ListItem = ({ navActivePages, link = {} }) => {
    const hasLinkPk = !!link.pk;

    const classes = classNames(styles['Header__Link'], {
        [styles['Header__Link--Active']]:
            hasLinkPk && navActivePages.includes(link.pk),
    });

    return (
        <li className={styles['Header__Item']}>
            <a className={classes} href={link.href} target={link.target}>
                {link.title}
            </a>
        </li>
    );
};

Header.propTypes = {};

export default Header;
